import React from "react";
import {LanguageProvider} from "../../contexts/Language/LanguageContext";
import Layout from "../layout/Layout";
import {WrapRefProvider} from "../../contexts/WrapRef/WrapRefContext"

export default function LanguageWraper({children}) {
    return (
        <LanguageProvider>
            <WrapRefProvider>
                <Layout>
                    {children}
                </Layout>
            </WrapRefProvider>
        </LanguageProvider>
    )
}