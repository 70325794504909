import React, {useState, useRef, useEffect} from "react";
import LogoSvg from "../svgs/Logo/Logo";
import NavItem from "../link/NavItem";
import navItems from "../link/navItems";
import Hearts from "../hearts/Hearts";
import SocialNetworks from "../social-networks/SocialNetworks";
import Language from "../language/Language";
import {navigation, navWrap, main, outer, inner, contentWrap, asideContent} from "./Layout.module.scss";
import Footer from "../footer/Footer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import useIsMobile from "../../utils/is-mobile/isMobile";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageMobile from "../../components/language/LanguageMobile";
import DrawerLayout from "../drawer/Drawer";
import CloseIcon from '@material-ui/icons/Close';
import useWrapRef from "../../contexts/WrapRef/WrapRefContext";


const Layout = ({children}) => {
  const isMobile = useIsMobile();
  const [opened, setOpened] = useState(false);
  const wrapRef = useRef();
  const wrapCtx = useWrapRef();
  useEffect(() => {
    wrapCtx.setWrapRef(wrapRef)
  }, []);
  return (
    <>
      <Helmet title={"I Mi Boke"}>
        <link rel="shortcut icon" type="image/jpg" href={favicon}/>
      </Helmet>
      <Drawer open={opened} anchor={"left"}>
        <DrawerLayout setOpened={setOpened} />
      </Drawer>
      <div ref={wrapRef} className={contentWrap}>
            <div className={outer} />
            <div className={inner} />
            {isMobile ? null : <Language />}
            {isMobile ? 
              (
              <>
               <div style={{position: "fixed", top: "0", left: "0", display: "flex", backgroundColor: "#1b3561", width: "100%", zIndex: 9999999}}>
                <IconButton onClick={() => setOpened(opened => !opened)}>
                  {opened ? 
                    <CloseIcon style={{color: "white", width: "40px", height: "40px"}} /> : 
                    <MenuIcon style={{color: "white", width: "40px", height: "40px"}} />}
                </IconButton>
                <LanguageMobile />
               </div>
                <main className={main}>
                        {children}
                </main>
               </>
              ) : 
              (<div className={navWrap}>
                <div role="aside" className={asideContent}>
                    <div className="logo" aria-hidden="true">
                      <LogoSvg />
                    </div>
                    <nav className={navigation}>
                      {navItems.map(n => (<NavItem key={n.key} name={n.key} to={n.to} />))}
                    </nav>
                </div>
                <main className={main}>
                        {children}
                </main>
              </div>)
          }
            {/*{isMobile ? null : <Hearts />}*/}
            <SocialNetworks />
      </div>
    </>
  )
}

export default Layout;