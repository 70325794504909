import React, {createContext, useContext, useState} from "react";

const LanguageState = createContext();
const LanguageSetter = createContext();

export const LanguageProvider = (props) => {
    const [lang, setLang] = useState("mne");
    return (
        <LanguageState.Provider value={lang}>
            <LanguageSetter.Provider value={setLang}>
                {props.children}
            </LanguageSetter.Provider>
        </LanguageState.Provider>
    )
}

export const useSetLang = () => {
    const setLang = useContext(LanguageSetter);
    return setLang;
}

const useLang = () => {
    const lang = useContext(LanguageState);
    return lang;
}

export default useLang;



