// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-all-news-index-js": () => import("./../../../src/pages/all-news/index.js" /* webpackChunkName: "component---src-pages-all-news-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donators-index-js": () => import("./../../../src/pages/donators/index.js" /* webpackChunkName: "component---src-pages-donators-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-index-js": () => import("./../../../src/pages/library/index.js" /* webpackChunkName: "component---src-pages-library-index-js" */),
  "component---src-pages-materials-index-js": () => import("./../../../src/pages/materials/index.js" /* webpackChunkName: "component---src-pages-materials-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-transaction-error-index-js": () => import("./../../../src/pages/transaction-error/index.js" /* webpackChunkName: "component---src-pages-transaction-error-index-js" */),
  "component---src-pages-transaction-success-index-js": () => import("./../../../src/pages/transaction-success/index.js" /* webpackChunkName: "component---src-pages-transaction-success-index-js" */),
  "component---src-templates-news-template-news-js": () => import("./../../../src/templates/news-template/News.js" /* webpackChunkName: "component---src-templates-news-template-news-js" */),
  "component---src-templates-project-template-project-js": () => import("./../../../src/templates/project-template/Project.js" /* webpackChunkName: "component---src-templates-project-template-project-js" */)
}

