import React from "react";
import {Link, navigate} from "gatsby";
import { container, wrapper} from "./Hearts.module.scss";
import translate from "../../utils/lang/langHash";
import useLang from "../../contexts/Language/LanguageContext";
import TextTransition, { presets } from "react-text-transition";
import FavoriteIcon from '@material-ui/icons/Favorite';

function Hearts () {
    const lang = useLang();
    return (
        <div className={container}>
            <div className={wrapper}>
                {/*<div onClick={() => navigate("/donate")} className={heartone} />*/}
                <div onClick={() => navigate("/donate")}>
                    <FavoriteIcon 
                    style={{width: "50px", height: "50px", color: "rgba(240, 91, 136, 1)", cursor: "pointer"}} 
                    />
                </div>
                <Link to="/donate">
                    <TextTransition
                    text={ translate("donate", lang) }
                    springConfig={ presets.stiff }
                    noOverflow
                    />
                </Link>
            </div>
            <div className={wrapper}>
                {/*<div onClick={() => navigate("/membership")} className={hearttwo} />*/}
                <div onClick={() => navigate("/membership")}>
                    <FavoriteIcon 
                    style={{width: "50px", height: "50px", color: "#00adee", cursor: "pointer"}} 
                    />
                </div>
                <Link to="/membership">
                <TextTransition
                text={ translate("membership", lang) }
                springConfig={ presets.stiff }
                noOverflow
                />
                </Link>
            </div>
        </div>
    )
}

export default Hearts;