import React, {createContext, useContext, useState} from "react";

const WrapRefContext = createContext();

export const WrapRefProvider = (props) => {
    const [wrapRef, setWrapRef] = useState(null);
    return (
        <WrapRefContext.Provider value={{wrapRef, setWrapRef}}>
            {props.children}
        </WrapRefContext.Provider>
    )
}

const useWrapRef = () => {
    const wrapRef = useContext(WrapRefContext);
    return wrapRef;
}

export default useWrapRef;