import React from "react";
import {container, item, lang, wrrapermne, wrraperen} from "./Language.module.scss";
import useLang, {useSetLang} from "../../contexts/Language/LanguageContext"

function Language () {
    const setLang = useSetLang();
    const language = useLang();
    return (
        <div className={container} role="group">
            <div className={item}>
                <div role="button" tabIndex="0" className={wrrapermne} onClick={() => setLang("mne")} aria-labelledby="lang-mne" aria-pressed={language === "mne"} >
                    {/* <img className={image} src={montenegro} /> */}
                </div>
                <p id="lang-mne" onClick={() => setLang("mne")} className={lang}>MNE</p>
            </div>
            <div className={item}>
                <div role="button" tabIndex="0" className={wrraperen} onClick={() => setLang("en")} aria-labelledby="lang-en" aria-pressed={language === "en"} >
                    {/* <img className={image} src={britain} /> */}
                </div>
                <p id="lang-en" onClick={() => setLang("en")} className={lang}>ENG</p>
            </div>
        </div>
    )
}

export default Language;