const navItems = [
  {
    key: "home",
    to: "/",
  },
  {
    key: "about-us",
    to: "/about-us",
  },
  {
    key: "projects",
    to: "/projects",
  },
  {
    key: "news-all",
    to: "/all-news",
  },
  {
    key: "library",
    to: "/library",
  },
  {
    key: "partners",
    to: "/partners",
  },
  {
    key: "donators",
    to: "/donators",
  },
  {
    key: "contact-us",
    to: "/contact-us",
  },
  {
    key: "materials",
    to: "/materials",
  },
  {
    key: "inclusive-community",
    to: "/news",
  },
];

export default navItems;
