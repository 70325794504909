import React from "react";
import useLang, {useSetLang} from "../../contexts/Language/LanguageContext";
import {container, item, lang, image, imageWrap} from "./Language.module.scss";
import montenegro from "../../images/montenegrosquare.png";
import britain from "../../images/britainsquare.jpg";

export default function LanguageMobile() {
    const setLang = useSetLang();
    const language = useLang();
    return (
        <header className={container} role="group">
            <div aria-label="crnogorski" className={imageWrap} onClick={() => setLang("mne")} tabIndex="0" role="button">
                <img className={image} alt="montenegro logo" src={montenegro} />
            </div>
            <div aria-label="english" className={imageWrap} onClick={() => setLang("en")} tabIndex="0" role="button">
                <img className={image} alt="britain logo" src={britain} />
            </div>
        </header>
    )
}