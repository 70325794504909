import React from "react";
import euLogo from "../../images/eu-logo.png";
import snazniLogo from "../../images/snazni169.png";
import ministarstvoLogo from "../../images/ministarstvo.png";
import resilience from "../../images/resilience169.png";
import {footerArea, image} from "./Footer.module.scss";
import useLang from "../../contexts/Language/LanguageContext";
import translate from "../../utils/lang/langHash";
import TextTransition, { presets } from "react-text-transition";
import useIsMobile from "../../utils/is-mobile/isMobile";

export default function Footer() {
    const lang = useLang();
    const isMobile = useIsMobile();
    return (
        <footer className={footerArea}>
              <div style={{display: "flex"}}>
              {lang === "en" ? 
              (
                <div style={{marginRight: isMobile ? "5px" : "10px"}}>
                  <img className={image} src={resilience} alt="resilience logo" />
                </div>
              ) : 
              (
                <div style={{marginRight: isMobile ? "5px" : "10px"}}>
                  <img className={image} src={snazniLogo} alt="snazni logo" />
                </div>
              )}
                <div style={{marginRight: isMobile ? "5px" : "10px"}}>
                  <img className={image} src={ministarstvoLogo} alt="ministarstvo javne uprave logo" />
                </div>
                <div style={{marginRight:  isMobile ? "20px" : "10px"}}>
                  <img className={image} src={euLogo} alt="eu logo" />
                </div>
                {isMobile ? null : (
                  <p style={{fontSize: "10px", alignSelf: "center", color: "white", marginTop: "2px", marginLeft: "10px", marginRight: "10px"}}>
                    { translate("disclaimer", lang) }
                  ‬</p>
                )}
              </div>
              {isMobile ? (
                <p style={{fontSize: "10px", color: "white", marginTop: "2px", marginLeft: "10px", marginRight: "10px"}}>
                { translate("disclaimer", lang) }
                ‬</p>
              ): null}
        </footer>
    )
}