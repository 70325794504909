import React from "react";
import {container, item, anchor} from "./SocialNetworks.module.scss"
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import useIsMobile from "../../utils/is-mobile/isMobile";

function SocialNetworks () {
    const isMobile= useIsMobile();
    return (
        <div className={container}>
            <div className={item}>
                <a className={anchor} href="https://www.facebook.com/Initiative-of-Youth-with-Disabilities-of-Boka-I-MI-Boke-111829123575190" target="_blank" rel="noreferrer" aria-label="Facebook">
                <FacebookIcon style={{color: "white", width: isMobile ? "20px" : "40px", height: isMobile ? "20px" :  "40px"}} />
                </a>
            </div>
            <div className={item}>
                <a className={anchor} href="https://www.instagram.com/i.mi.boke_iydb/" target="_blank" rel="noreferrer" aria-label="Instagram">
                <InstagramIcon style={{color: "white", width: isMobile ? "20px" :  "40px", height: isMobile ? "20px" :  "40px"}} />
                </a>
            </div>
            <div className={item}>
                <a className={anchor} href="https://twitter.com/imiboke_iydb" target="_blank" rel="noreferrer" aria-label="Twitter">
                <TwitterIcon style={{color: "white", width: isMobile ? "20px" :  "40px", height: isMobile ? "20px" :  "40px"}} />
                </a>
            </div>
            <div className={item}>
                <a className={anchor} href="https://www.youtube.com/channel/UCA6TZKW4sYQ_Hk7taR57sVA" target="_blank" rel="noreferrer" aria-label="Youtube">
                <YouTubeIcon style={{color: "white", width: isMobile ? "20px" :  "40px", height: isMobile ? "20px" :  "40px"}} />
                </a>
            </div>
        </div>
    )
}

export default SocialNetworks;