import React from "react";
import useLang from "../../contexts/Language/LanguageContext";
import navItems from "../link/navItems";
import NavItem from "../link/NavItem";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import logo from "../../images/drawerlogo.jpg";
import {image, navigation} from "./Drawer.module.scss";


const additionalItems = [
    {
        key: "donate",
        to: "/donate"
    },
    {
        key: "membership",
        to: "/membership"
    }
]

export default function DrawerLayout({setOpened}) {
    const lang = useLang();
    return (
        <div>
            <div>
                <IconButton onClick={() => setOpened(false)}>
                    <CloseIcon style={{color: "black", width: "40px", height: "40px"}} />
                </IconButton>
            </div>
            <div>
                <img className={image} src={logo} alt={"i mi boke logo"} />
            </div>
            <nav className={navigation}>
                {navItems.map((n, i) => (
                    <div key={i} onClick={() => setOpened(false)}>
                        <NavItem key={n.key} name={n.key} to={n.to} />
                    </div>
                    ))}
                {/*{additionalItems.map((n, i) => (
                    <div key={i} onClick={() => setOpened(false)}>
                        <NavItem key={n.key} name={n.key} to={n.to} />
                    </div>
                    ))}*/}
            </nav>
        </div>
    )
}

/*
    <div role="aside">
        <div className="logo" aria-hidden="true">
          <LogoSvg />
        </div>
        <nav className={navigation}>
          {navItems.map(n => (<NavItem key={n.key} name={n.key} to={n.to} />))}
        </nav>
        </div>
 */